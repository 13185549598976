import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Styles/Signup.css'; // Import your CSS file
import logo from '../images/balancelogo.png';

const Signup = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!email || !password) {
            setMessage('All fields are required.');
            return;
        }

        // if (!acceptedTerms) {
        //     setMessage('You must accept the terms and conditions to register.');
        //     return;
        // }

        const url = `https://blockchain.fufi.info/registration/${email}/${password}`;

        try {
            const response = await fetch(url);

            if (response.ok) {
                const data = await response.json();
                if (data.status) {
                    setMessage('Registration successful!');
                    localStorage.setItem('token', data.token); // Store the token in localStorage
                    navigate(`/Wallet/${email}`);
                } else {
                    setMessage(`Registration failed: ${data.result}`);
                }
            } else {
                const errorData = await response.json();
                setMessage(`Registration failed: ${errorData.result}`);
            }
        } catch (error) {
            setMessage(`Error: ${error.message}`);
        }
    };

    return (
        <div className='login-details'>
            <div className='login-card'>
            \
                <div className='Welcome'><solid>Welcome</solid></div>
                <span>Sign Up Fufi Explorer.</span>
                <div className='loginform'>
                    <form onSubmit={handleSubmit}>
                        {message && <div className="alert alert-info mt-3">{message}</div>}
                        <div className="form-group">
                            <input
                                type="email"
                                className="form-control1"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="Enter email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>


                        <div className="form-group">
                            <input
                                type="password"
                                className="form-control1"
                                id="exampleInputPassword1"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        {/* <label className="form-check-label" htmlFor="termsCheck">
                    I accept the terms and conditions
                </label> */}
                        <button type="submit" className="btn btn-primary">Continue</button>
                        <div className='dont-account'>Already have an account?</div>
                        <div className='Sign-up' onClick={() => navigate(`/Account`)}>
                            <strong>Login</strong>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    );
};

export default Signup;
