import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Bar } from "react-chartjs-2"; // Import Bar component from react-chartjs-2
import { Chart as ChartJS } from "chart.js/auto"; // Import ChartJS module for proper functioning
import "../Styles/TransactionGraph.css"; // Import your CSS file for styling
import transactionlogo from "../images/transactiondaily.png";
import pricelogo from "../images/price.png";
import marketcap from "../images/marketcap.png";

const TransactionGraph = () => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: "rgba(75, 192, 192, 0.2)", // Light background color for the bars
        borderColor: "rgba(75, 192, 192, 1)", // Border color of the bars
        borderWidth: 2,
        shadowBlur: 10, // Shadow blur
        shadowColor: "rgba(0, 0, 0, 0.5)", // Shadow color
      },
    ],
  });
  const chartRef = useRef(null);
  const [fufiprice, setfufiprice] = useState(1000);
  const [twentyFourHoursAgotxn, settwentyFourHoursAgotxn] = useState(10);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://blockchain.fufi.info/getTransactionGraph"
        );
        const transactions = response.data.result || [];
        const labels = transactions.map((tx) =>
          new Date(tx.createdAt).toLocaleString()
        );
        const values = transactions.map((tx) => tx.count);

        setChartData({
          labels,
          datasets: [
            {
              label: "Txn/h", // Add label
              data: values,
              backgroundColor: "rgba(75, 192, 192, 0.2)", // Light background color for the bars
              borderColor: "rgba(75, 192, 192, 1)", // Border color of the bars
              borderWidth: 2,
              shadowBlur: 10, // Shadow blur
              shadowColor: "rgba(0, 0, 0, 0.5)", // Shadow color
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching transaction data:", error);
        setChartData({
          labels: [],
          datasets: [],
        });
      }
    };

    fetchData();

    // Cleanup function to destroy the chart instance
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    const fetchPrice = async () => {
      try {
        const response = await fetch("https://sid.fufi.info/getfufiprice");
        const data = await response.json();
        setfufiprice(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchPrice();
  }, []);

  useEffect(() => {
    const fetchlasttxn = async () => {
      try {
        const response = await fetch(
          "https://blockchain.fufi.info/getHomepagedata"
        );
        const data = await response.json();
        settwentyFourHoursAgotxn(data.result);
      } catch (error) {
        console.error(error);
      }
    };
    fetchlasttxn();
  }, []);

  // Custom options for the chart
  const options = {
    scales: {
      x: {
        ticks: {
          display: false, // Hide x-axis labels
        },
        grid: {
          display: false, // Hide x-axis grid
        },
      },
      y: {
        ticks: {
          display: false, // Hide y-axis labels
        },
        grid: {
          display: false, // Hide y-axis grid
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Hide legend
      },
      tooltip: {
        enabled: true, // Enable tooltips
        backgroundColor: "rgba(163, 37, 33, 0.596)", // Tooltip background color
        titleColor: "rgba(163, 37, 33, 0.596)", // Tooltip title color
        bodyColor: "rgba(163, 37, 33, 0.596)", // Tooltip body color
        titleFont: {
          family: "Arial", // Tooltip title font family
          size: 12, // Tooltip title font size
          weight: "bold", // Tooltip title font weight
        },
        bodyFont: {
          family: "Arial", // Tooltip body font family
          size: 12, // Tooltip body font size
        },
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
  };

  return (
    <div className="containerMainIn-fourth">
      <div className="chart-container">
        <div className="cardchart">
          <div className="boxID">
            <div className="headingd">Daily Transactions</div>
            <span className="DailyTxn">
              {twentyFourHoursAgotxn.twentyFourHoursAgotxn}
            </span>
          </div>
          <div className="boxID">
            {chartData.labels.length > 0 ? (
              <Bar ref={chartRef} data={chartData} options={options} /> // Use Bar component
            ) : (
              <p>Loading...</p>
            )}
          </div>
        </div>
        <div className="cardchart">
          <div className="grid-chart">
            <div className="chart-title">
              <span>Daily transactions</span>
              <div className="subtitle">
                {twentyFourHoursAgotxn.twentyFourHoursAgotxn}
              </div>
            </div>
          </div>
          <div className="grid-chart">
            <div className="chart-title">
              <span>Fufi price</span>
              <div className="subtitle">₹{fufiprice.price}</div>
            </div>
          </div>
          <div className="grid-chart">
            <div className="chart-title">
              <span>Market cap</span>
              <div className="subtitle">₹{fufiprice.marketcap}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionGraph;
