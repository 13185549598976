import React, { useState } from "react";
import axios from "axios";
import "../Styles/DeployContract.css";

const DeployContract = () => {
  const [file, setFile] = useState(null);
  const [contractName, setContractName] = useState("");
  const [privatekey, setPrivatekey] = useState("");
  const [contractType, setContractType] = useState("");
  const [message, setMessage] = useState("");
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [contractAddress, setContractAddress] = useState("");

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setCode(e.target.result);
      };
      reader.readAsText(selectedFile);
    }
  };

  const handleContractNameChange = (event) => {
    setContractName(event.target.value);
  };

  const handlePrivatekeyChange = (event) => {
    setPrivatekey(event.target.value);
  };

  const handleContractTypeChange = (event) => {
    setContractType(event.target.value);
  };

  const handleCopyAddress = () => {
    if (contractAddress) {
      navigator.clipboard
        .writeText(contractAddress)
        .then(() => {
          setMessage("Contract address copied to clipboard!");
        })
        .catch((error) => {
          setMessage(`Failed to copy contract address: ${error.message}`);
        });
    }
  };

  const callAPIAfterDeploy = async (code, contractAddress) => {
    try {
      const response = await axios.post(
        "https://blockchain.fufi.info/deployContract",
        { contractAddress, code }
      );
      console.log("API call result:", response.data); // Assuming response.data is what you expect
      // Handle success as needed
    } catch (error) {
      console.error("Error calling API:", error.message);
      // Handle error as needed
    }
  };

  const callAPIAfterDeployAbi = async (contractAddress) => {
    try {
      const response = await axios.get(
        `https://blockchain.fufi.info/ContractAbi/${contractAddress}`
      );
      console.log("API call result:", response.data); // Assuming response.data is what you expect
      // Handle success as needed
    } catch (error) {
      console.error("Error calling API:", error.message);
      // Handle error as needed
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file || !contractName || !privatekey || !contractType) {
      setMessage("Please fill in all fields.");
      return;
    }

    const formData = new FormData();
    formData.append("contractFile", file);
    formData.append("contractName", contractName);
    formData.append("privatekey", privatekey);
    formData.append("contractType", contractType);

    setLoading(true);

    try {
      const response = await axios.post(
        "https://blockchain.fufi.info/deploy",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setContractAddress(response.data.result);
      window.alert("Contract deployed successfully");
      setLoading(false);

      // Call API with contract code and address after successful deployment
      await callAPIAfterDeploy(code, response.data.result);
      await callAPIAfterDeployAbi(response.data.result);
    } catch (error) {
      setLoading(false);
      if (error.response) {
        setMessage(
          `Error uploading file: ${
            error.response.data.message || error.response.statusText
          }`
        );
      } else if (error.request) {
        setMessage("Error uploading file: No response from server.");
      } else {
        setMessage(`Error uploading file: ${error.message}`);
      }
    }
  };

  return (
    <div className="upload-container">
      <h2>Deploy Contract</h2>
      <form onSubmit={handleSubmit} className="upload-form">
        <div className="form-group">
          <label>Contract Name:</label>
          <input
            type="text"
            value={contractName}
            onChange={handleContractNameChange}
            required
            className="input-field"
          />
        </div>
        <div className="form-group">
          <label>Private Key:</label>
          <input
            type="text"
            value={privatekey}
            onChange={handlePrivatekeyChange}
            required
            className="input-field"
          />
        </div>
        <div className="form-group">
          <label>Contract Type:</label>
          <select
            value={contractType}
            onChange={handleContractTypeChange}
            className="input-field"
            required
          >
            <option value="">Select Contract Type</option>
            <option value="RBC20">Token</option>
            <option value="DApp">DApp / NFT</option>
          </select>
        </div>
        <div className="form-group">
          <input
            type="file"
            accept=".sol"
            onChange={handleFileChange}
            required
            className="input-file"
          />
        </div>
        <button type="submit" className="upload-button" disabled={loading}>
          {loading ? "Deploying..." : "Deploy"}
        </button>
      </form>
      {contractAddress && (
        <div className="address-container">
          <p className="address">Contract Address: {contractAddress}</p>
          <button className="copy-button" onClick={handleCopyAddress}>
            Copy Address
          </button>
          {message && <p className="message">{message}</p>}
        </div>
      )}
      {code && (
        <div className="code-container">
          <h3>Contract Code:</h3>
          <pre className="code-box">{code}</pre>
        </div>
      )}
    </div>
  );
};

export default DeployContract;
