import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Modal, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';  // Import Bootstrap CSS
import '../Styles/Wallet.css';
import createwalletlogo from '../images/createwalletlogo.png';
import importwallet from '../images/importwallet.png';

const Wallet = () => {
    const [showModal, setShowModal] = useState(false);
    const [showResponseModal, setShowResponseModal] = useState(false);
    const [showImportModal, setShowImportModal] = useState(false);
    const [apiResponse, setApiResponse] = useState('');
    const [privatkey, setPrivatkey] = useState('');
    const [importPrivateKey, setImportPrivateKey] = useState('');
    const [message, setMessage] = useState('');
    const [hasCopiedPrivateKey, setHasCopiedPrivateKey] = useState(false);
    const [showCopyWarning, setShowCopyWarning] = useState(false);

    const { email } = useParams();
    const navigate = useNavigate();
    const [copySuccess, setCopySuccess] = useState('');

    useEffect(() => {
        const fetchBlocks = async () => {
            try {
                const response = await fetch(`https://blockchain.fufi.info/getwalletdata/${email}`);
                const data = await response.json();
                console.log("data", data);
                if (data.status) {
                    navigate(`/Dashboard/${email}`);
                }
            } catch (error) {
                console.error(error);
                // Display an error message to the user
            }
        };
        fetchBlocks();
    }, [email, navigate]);

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);
    const handleImportShow = () => setShowImportModal(true);
    const handleImportClose = () => setShowImportModal(false);

    const handleCreateWallet = async () => {
        try {
            const response = await fetch('https://blockchain.fufi.info/createwallet');
            const data = await response.json();
            if (response.ok) {
                console.log('Wallet created successfully', data);
                setPrivatkey(data.result.privateKey);
                setApiResponse('Wallet created successfully');
            } else {
                console.error('Failed to create wallet', data);
                setApiResponse('Failed to create wallet: ' + data.message);
            }
        } catch (error) {
            console.error('Error creating wallet', error);
            setApiResponse('Error creating wallet: ' + error.message);
        } finally {
            handleClose();  // Close the first modal
            setShowResponseModal(true);  // Show the response modal
        }
    };

    const handlewalletlink = async () => {
        if (!hasCopiedPrivateKey) {
            setShowCopyWarning(true);
            return;
        }
        const url = `https://blockchain.fufi.info/importwallet/${email}/${privatkey}`;
        try {
            const response = await fetch(url);
            if (response.ok) {
                const data = await response.json();
                if (data.status) {
                    setMessage(data.msg);
                    navigate(`/Dashboard`);
                } else {
                    setMessage(`Link wallet failed: ${data.msg}`);
                }
            } else {
                const errorData = await response.json();
                setMessage(`Link wallet failed: ${errorData.message}`);
            }
        } catch (error) {
            setMessage(`Error: ${error.message}`);
        } finally {
            handleResponseClose();  // Close the response modal
        }
    };

    const handleImportWallet = async () => {
        const url = `https://blockchain.fufi.info/importwallet/${email}/${importPrivateKey}`;
        try {
            const response = await fetch(url);
            if (response.ok) {
                const data = await response.json();
                if (data.status) {
                    setMessage(data.msg);
                    navigate(`/Dashboard`);
                } else {
                    setMessage(`Link wallet failed: ${data.msg}`);
                }
            } else {
                const errorData = await response.json();
                setMessage(`Link wallet failed: ${errorData.message}`);
            }
        } catch (error) {
            setMessage(`Error: ${error.message}`);
        } finally {
            handleImportClose();  // Close the import modal
        }
    };

    const handleResponseClose = () => {
        setShowResponseModal(false);
        setCopySuccess('');
        setShowCopyWarning(false);
    };

    return (
        <div className='wallet-container'>
            <div className='Wallet-heading'>
                <strong>Access my Wallet</strong>
                <div className='Wallet-heading wallet-message'>
                Please select a method to link a wallet or create.
            </div>
            </div>
            
            <div className='wallet-cardContainer'>
            <div className='wallet-card' onClick={handleShow}>
                <img src={createwalletlogo} alt="Create Wallet Logo" className="createwalletlogo" />
             
                <div className='Wallet-heading1'>
                    <p><strong>Create Wallet</strong></p>
                    Using a private key online makes your wallet more vulnerable to loss of funds. We don’t recommend this method of wallet creation.
                </div>
            </div>

            <div className='wallet-card' onClick={handleImportShow}>
                <img src={importwallet} alt="Import Wallet Logo" className="createwalletlogo" />
                
                <div className='Wallet-heading1'>
                    <p><strong>Import Wallet</strong></p>
                    Your private key is not stored by us. Please ensure you keep it secure and do not share it with anyone.
                </div>
            </div>
            </div>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Terms and Conditions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div></div>
                    Please read and accept the terms and conditions before creating a wallet.
                    Warning !! : kindly copy the private key in the next step. If the key is lost
                    you will loose your wallet
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleCreateWallet}>
                        Accept and Create Wallet
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showImportModal} onHide={handleImportClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Import Wallet</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formPrivateKey">
                            <Form.Label>Private Key</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your private key"
                                value={importPrivateKey}
                                onChange={(e) => setImportPrivateKey(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleImportClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleImportWallet}>
                        Confirm to link wallet
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showResponseModal} onHide={handleResponseClose} size="lg">
                <Modal.Header closeButton>
                    {/* <Modal.Title>API Response</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    <div>{apiResponse}</div>

                    {copySuccess && <div style={{ color: 'green', marginTop: '10px' }}>{copySuccess}</div>}
                    {privatkey && (
                        <div style={{ wordWrap: 'break-word', marginTop: '10px' }}>
                            <strong>Private Key:</strong> {privatkey}
                            <div style={{ marginTop: '10px' }}>
                                <CopyToClipboard text={privatkey} onCopy={() => { setCopySuccess('Copied!'); setHasCopiedPrivateKey(true); }}>
                                    <Button variant="outline-primary">Copy</Button>
                                </CopyToClipboard>
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handlewalletlink} disabled={!hasCopiedPrivateKey}>
                        Go to my wallet
                    </Button>
                    {showCopyWarning && !hasCopiedPrivateKey && (
                        <div style={{ color: 'red', marginTop: '10px' }}>
                            Please copy the private key before confirming to link the wallet.
                        </div>
                    )}
                </Modal.Footer>
            </Modal>
            {message && <div>{message}</div>}
        </div>
    );
};

export default Wallet;
