import React, { useState } from "react";
import "../Styles/Searchbar.css"; // Import your CSS file for styling
import search from "../images/search.png"; // Make sure to update the path to your logo
import { useNavigate } from 'react-router-dom';
import addresspng from "../images/address.png";

const Invalidinput = () => {


 

  return (
    <div className="Invalidinput">
            <p>No results found</p>

    </div>
  );
};

export default Invalidinput;
