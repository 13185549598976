import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Row, Col } from "react-bootstrap";
import transactionlogo from "../images/transaction.png";
import Block from "../images/icons/ghkk.png";

const TransactionTable = () => {
  const [transactions, setTransactions] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await fetch(`https://blockchain.fufi.info/getTransction`);
        const data = await response.json();
        setTransactions(data.result);
      } catch (error) {
        console.error(error);
      }
    };
    fetchTransactions();
  }, []);

  useEffect(() => {
    const fetchBlocks = async () => {
      try {
        const response = await fetch("https://blockchain.fufi.info/getBlockdatadata");
        const data = await response.json();
        setBlocks(data.result);
      } catch (error) {
        console.error(error);
      }
    };
    fetchBlocks();
  }, []);

  const shortenAddress = (length, address) => {
    return `${address.slice(0, length)}...${address.slice(-4)}`;
  };

  const formatTimeAgo = (date) => {
    const dates = new Date(date).getTime();
    const dateInSeconds = Math.floor(dates / 1000);
    let currentTime = Math.floor(Date.now() / 1000);
    let second = currentTime - dateInSeconds;
    if (second < 60) {
      return `${second} sec ago`;
    } else if (second < 3600) {
      let minutes = Math.floor(second / 60);
      return `${minutes} m ago`;
    } else if (second < 86400) {
      let hours = Math.floor(second / 3600);
      return `${hours} h ago`;
    } else {
      let days = Math.floor(second / 86400);
      return `${days} day ago`;
    }
  };

  const formatTimeAgoblock = (timestamp) => {
    let currentTime = Math.floor(Date.now() / 1000);
    let times = currentTime - timestamp;
    return `${times} sec ago`;
  };
//'copyToClipboard' is assigned a value but never used
  // const copyToClipboard = (content) => {
  //   navigator.clipboard.writeText(content);
  // };

  const handleTransactionClick = (hash) => {
    navigate(`/transaction/${hash}`);
  };

  const handleallTransactionClick = () => {
    navigate(`/Alltransactions`);
  };

  const handleBlockClick = (blockNumber) => {
    navigate(`/Block/${blockNumber}`);
  };

  const handleAllBlockClick = () => {
    navigate(`/AllBlock`);
  };

  const handleAlladdressClick = (address) => {
    navigate(`/address/${address}`);
  };

  const trimValue = (value) => {
    let trimmedValue = Number(value).toFixed(5);
    trimmedValue = parseFloat(trimmedValue).toString();
    return trimmedValue;
  };

  return (
    <>
        <div className='containerMainIn-fifth'>
    <Row className="transaction-container">
      <Col md={5}>
        <div className="block-cards">
        <h4 className="headingBlocks">Blocks</h4>
        {blocks.map((block) => (
                <div key={block.blockNumber} className="innErBoxContent" onClick={() => handleBlockClick(block.blockNumber)} style={{ cursor: "pointer" }}>     
        
        <div className="innErBoxCon-Box">
        <img src={Block} alt="block logo" className="block-logo" />
</div>
<div className="innErBoxCon-Box">
<span className="block-blockNumber">{block.blockNumber}</span>
<span className="block-timestamp">{formatTimeAgoblock(block.timestamp)}</span>
</div>
<div className="innErBoxCon-Box">
<span className="blockHash-Validator">EBP </span> 
<span className="block-Trx">Txn {block.Trx}</span>
</div>
<div className="innErBoxCon-Box">
<span className="blockhash">{shortenAddress(6, block.blockHash)}</span>
<span className="blockhash1">{shortenAddress(16, block.blockHash)}</span>
</div>

        </div>
   ))}

         
          {/* <Table table>
            <tbody>
              {blocks.map((block) => (
                <tr key={block.blockNumber} onClick={() => handleBlockClick(block.blockNumber)} style={{ cursor: "pointer" }}>
                  <td>
                    <div className="tableBxd">
                      <img src={Block} alt="block logo" className="block-logo" />
                      <span className="block-blockNumber">{block.blockNumber}</span>
                      <br />
                      <div className="block-timestamp">{formatTimeAgoblock(block.timestamp)}</div>
                      <br />
                      <div className="block-Trx">Txn {block.Trx}</div>
                      <br />
                      <div className="blockHash-Validator">EBP </div> 
                      <div className="blockhash">{shortenAddress(6, block.blockHash)}</div>
                      <div className="blockhash1">{shortenAddress(16, block.blockHash)}</div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table> */}












<hr style={{ margin: "15px 0", color: "lightblue" }} />
          <div className="alltransactions" onClick={handleAllBlockClick}>
            View all Blocks
          </div>
          

        </div>
      </Col>
      <Col md={7}>
        
        <div className="transaction-body">
        <h4 className="headingTransactions">Large Transactions</h4>
          <div className="transaction-grid">
            {transactions.map((transaction, index) => (
              <div className="transaction-item" key={index}>
                <div className="transaction-info">
                  <div className="transaction-status">
                    {transaction?.transctiontype === 1
                      ? ' Transfer'

                      : transaction?.transctiontype === 2
                        ? 'Contract Call'
                        : 'Coin transfer'}
                  </div>
                  <div className="transaction-hash" onClick={() => handleTransactionClick(transaction.hash)} style={{ cursor: "pointer" }}>
                    <img src={transactionlogo} alt="block logo" className="transactionlogo" />
                    {shortenAddress(6, transaction.hash)}
                  </div>
                </div>
                <div className="transaction-address">
                  <span onClick={() => handleAlladdressClick(transaction.from)} style={{ cursor: "pointer" }}>
                    From {shortenAddress(7, transaction.from)}
                  </span>
                  <span onClick={() => handleAlladdressClick(transaction.to)} style={{ cursor: "pointer" }}>
                    To {shortenAddress(7, transaction?.to === '0' ? transaction.contract : transaction.to)}
                  </span>
                </div>
                <div className="transaction-value-fee">
                  <div className="transaction-value">{trimValue(transaction.value)} Value</div>
                  <div className="transaction-fee">{trimValue(transaction.fee)} Fee</div>
                </div>
                <div className="transaction-details">
                  <div className="transactionblock-number">{transaction.blockNumber}</div>
                  <div className="transaction-time">{formatTimeAgo(transaction.createdAt)}</div>
                </div>
              </div>
            ))}
          </div>
          <hr style={{ margin: "15px 0", color: "lightblue" }} />
          <div className="alltransactions" onClick={handleallTransactionClick}>
            View all Transactions
          </div>
        </div>
      </Col>
    </Row>
    </div>
    </>

  );
};

export default TransactionTable;
