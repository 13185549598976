import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "../Styles/Alltransactions.css";

const Topaccounts = () => {
  const [transactions, setTransactions] = useState([]);
  const navigate = useNavigate();
  const [pagenumber, setPagenumber] = useState(1);
  const [pagelength] = useState(20); // Set a default page length

  const handleAlladdressClick = (address) => {
    navigate(`/address/${address}`);
  };

  const shortenAddress = (length, address) => {
    return `${address.slice(0, length)}..${address.slice(-8)}`;
  };

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await fetch(`https://blockchain.fufi.info/getTopaccounts/${pagenumber}/${pagelength}`);
        const data = await response.json();
        setTransactions(data.result || []);

        // Fetch tags for each address after fetching transactions
       
      } catch (error) {
        console.error('Error fetching all transactions:', error);
      }
    };

    fetchTransactions();
  }, [pagenumber, pagelength]);



  const trimValue = (value) => {
    let trimmedValue = Number(value).toFixed(5);
    trimmedValue = parseFloat(trimmedValue).toString();
    return trimmedValue;
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0) {
      setPagenumber(newPage);
    }
  };

  return (
    <div className="Alltransaction-detail-container">
      <div className="topaccounts-heading">Top Accounts</div>
      <div className="pagination">
        <span className="pagination-page-first" onClick={() => setPagenumber(1)}>
          First
        </span>
        <span className="pagination-button" onClick={() => handlePageChange(pagenumber - 1)}>
          <i className="fas fa-chevron-left"></i>
        </span>
        <span className="pagination-page-number">
          {pagenumber}
        </span>
        <span className="pagination-button-next" onClick={() => handlePageChange(pagenumber + 1)}>
          <i className="fas fa-chevron-right"></i>
        </span>
      </div>
      <div className="alltransaction-body">
        <div className="alltransaction-grid">
          {transactions.map((transaction, index) => (
            <div className="topaddress-item" key={index}>
              <span className='topaccounts-address'
                onClick={() => handleAlladdressClick(transaction.address)}
                style={{ cursor: "pointer" }}
              >
                <span className='Topaccounts-index'>
                  {(pagenumber - 1) * pagelength + index + 1}{' '}
                </span>
                {shortenAddress(20, transaction.address)}
              </span>
              <span className="transaction-value">
                {trimValue(transaction.balance)} Balance
              </span>
              <span className="transaction-fee">
                {(transaction.balance / 650000000) * 100} Percentage
              </span>
              <span className="transaction-tag">
                <strong>sid</strong>
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Topaccounts;
