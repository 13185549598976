import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "../Styles/Alladdress.css";
import transactionlogo from '../images/transaction.png';
import addresspng1 from '../images/flogo.png';
import balancelogo from '../images/flogo.png';



const Alladdress = () => {
  const { address } = useParams();
  const [alladdress, setaddress] = useState(null);

  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("Transactions");

  const [pagenumber, setPagenumber] = useState(1);
  const [pagelength] = useState(20); // Set a default page length
  const [tags, setTags] = useState([]);
  const [sidinfo, setSidinfo] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const fetchTag = async (address) => {
    try {
      const response = await fetch(`https://sid.fufi.info/gettag/${address}`);
      const data = await response.json();
      const result = data.result;
      const keysWithValueOne = Object.keys(result).filter(key => result[key] === 1);
      console.log("keysWithValueOne", keysWithValueOne)
      setTags(keysWithValueOne);
      setSidinfo(data.data1)
    } catch (error) {
      console.error('Error fetching tag:', error);
    }
  };
  useEffect(() => {
    const fetchBlocks = async () => {
      try {
        const response = await fetch(
          `https://blockchain.fufi.info/getuserTransction/${address}/${pagenumber}/${pagelength}`
        );
        await fetchTag(address);

        const data = await response.json();
        setaddress(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchBlocks();
  }, [address, pagenumber, pagelength]);

  // Render loading state if data is not yet available
  if (!alladdress) {
    return <div>Loading...</div>;
  }


  const handleTransactionClick = (hash) => {
    navigate(`/transaction/${hash}`);
  };


  const handleAlladdressClick = (address) => {
    navigate(`/address/${address}`);
  };
  const shortenAddress = (length, address) => {
    return `${address.slice(0, length)}..${address.slice(-4)}`;
  };



  const trimValue = (value) => {
    let trimmedValue = Number(value).toFixed(5);
    trimmedValue = parseFloat(trimmedValue).toString();
    return trimmedValue;
  };

  const formatTimeAgo = (date) => {
    const dates = new Date(date).getTime();
    const dateInSeconds = Math.floor(dates / 1000);
    let currentTime = Math.floor(Date.now() / 1000);
    let second = currentTime - dateInSeconds;
    if (second < 60) {
      return `${second}s ago`;
    } else if (second < 3600) {
      let minutes = Math.floor(second / 60);
      return `${minutes}m ago`;
    } else if (second < 86400) {
      let hours = Math.floor(second / 3600);
      return `${hours}h ago`;
    } else {
      let days = Math.floor(second / 86400);
      return `${days}d ago`;
    }
  };


  const handlePageChange = (newPage) => {
    if (newPage > 0) {
      setPagenumber(newPage);
    }
  };


  const handleIconClick = () => {
    setShowPopup(!showPopup);
  };
  return (
    <div className="address-detail-container">
      {/* <Search /> */}
      <div className="addressheading">Address details</div>

      <div className="alladdress"><img
        src={addresspng1}
        alt="addresspng logo"
        className="alladdresslogo"
      />
        {address}
      </div>
      <div className="alladdress1"><img
        src={addresspng1}
        alt="addresspng logo"
        className="alladdresslogo"
      />
        {shortenAddress(28, address)}
      </div>
      <div className="transaction-detail">
        <span className="key"><i class="bi bi-info-square"></i>Balance</span>
        <span className="text-value" style={{ color: "#101112CC" }}>  <img
          src={balancelogo}
          alt="info logo"
          className="infologo" />{alladdress.balance} Fufi</span>

        <span className="key"><i class="bi bi-info-square"></i>Transactions</span>
        <span className="text-value">{alladdress.trx_length}</span>

        <span className="key"> <i class="bi bi-info-square"></i>Tag</span>
        <span className="text-value">
          {tags.length > 0 ? (
            tags.map((tag, index) => (
              <span key={index}>
                {tag}
                {index < tags.length - 1 && ' '} {/* Add a space except after the last tag */}
              </span>
            ))
          ) : (
            <span>No tags found</span>
          )}
        </span>
        <span className="key">
          <i className="bi bi-info-square" onClick={handleIconClick}></i> weightage
        </span>
        <span className="text-value">{sidinfo?.avg}</span>
        {tags.length > 0 ? (
          showPopup && (
            <div className="popup-box">
              <p>Total Burn: {sidinfo?.totalburn}</p>
              <p>Old Burn: {sidinfo?.oldburn}</p>
              <p>Burn: {sidinfo?.burn}</p>
              <p>User Total Burn: {sidinfo?.usertotalburn}</p>
              <p>Formula: {sidinfo?.usertotalburn} * 100 / {sidinfo?.totalburn}</p>
              <p>Weightage: {sidinfo?.avg}</p>
              <button onClick={handleIconClick}>Close</button>
            </div>
          )

        ) : (
          <span>`</span>
        )}

        <span className="key"> <i class="bi bi-info-square"></i>Gas used</span>
        <span className="text-value">{alladdress.Gasused}</span>

        <span className="key"><i class="bi bi-info-square"></i> Last balance update</span>
        <span className="text-value">{alladdress.latestBlockNumber}</span>

        <div className="address-tabs">
          {["Transactions", "Token transfers", "Tokens", "Internal txns"].map((tab) => (
            <button
              key={tab}
              className={`address-button ${activeTab === tab ? "active" : ""}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}


        </div>

        <div className="pagination">
          <span className="pagination-page-first" onClick={() => setPagenumber(1)}>
            First
          </span>
          <span className="pagination-button" onClick={() => handlePageChange(pagenumber - 1)}>
            <i className="fas fa-chevron-left"></i>
          </span>
          <span className="pagination-page-number">
            {pagenumber}
          </span>
          <span className="pagination-button-next" onClick={() => handlePageChange(pagenumber + 1)}>
            <i className="fas fa-chevron-right"></i>
          </span>
        </div>
        {activeTab === "Transactions" && (
          <div className="alltransaction-body">
            <div className="alltransaction-grid">
              {alladdress.result.map((transaction, index) => (
                <div className="address-item" key={index}>
                  <div className="transaction-info">
                    <div className="transaction-status">
                      {transaction?.transctiontype === 1
                        ? 'Transfer'
                        : transaction?.transctiontype === 2
                          ? 'Contract call'
                          : 'Coin transfer'}
                    </div>
                  </div>
                  <div className="transaction-hash"
                    onClick={() => handleTransactionClick(transaction.hash)}
                    style={{ cursor: "pointer" }}>
                    <img
                      src={transactionlogo}
                      alt="block logo"
                      className="transactionlogo"
                    />
                    {shortenAddress(8, transaction.hash)}
                  </div>

                  <span className="alltransaction-from"
                    onClick={() => handleAlladdressClick(transaction.from)}
                    style={{ cursor: "pointer" }}
                  >
                    {shortenAddress(6, transaction.from)}
                  </span>
                  <span
                    className="alltransaction-to"
                    onClick={() => handleAlladdressClick(transaction.to)}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="bi bi-arrow-right-square-fill"></i>
                    {shortenAddress(
                      6,
                      transaction?.to === '0' ? transaction.contract : transaction.to
                    )}
                  </span>
                  <span className="transaction-value">
                    {trimValue(transaction.value)} Value
                  </span>
                  <span className="transaction-fee">
                    {trimValue(transaction.fee)} Fee
                  </span>

                  <span className="transactionblock-number">
                    {transaction.blockNumber} Block
                  </span>

                  <div className='transaction-type'> {address === transaction.from ? 'Send' : 'Received'}</div>
                  <span className="transaction-time1">{formatTimeAgo(transaction.createdAt)}</span>
                </div>
              ))}
            </div>
          </div>
        )}

        {activeTab === "Token transfers" && (
          <div className="Token-transfers">No Token transaction</div>
        )}
        {activeTab === "Tokens" && (
          <div className="Tokens"> No Tokens </div>
        )}

        {activeTab === "Internal txns" && (
          <div className="Internal-txns"> Internal txns </div>
        )}

      </div>


    </div>
  );
};

export default Alladdress;
