import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Row, Col } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";

import "../Styles/Alltransactions.css"; // Import your CSS file for styling
import Search from "./Searchbar";

import '../Styles/TransactionTable.css';
import success from '../images/success.svg';
import transactionlogo from '../images/transaction.png';
import Block from '../images/Block.png';
import arrow from '../images/arrow.png';
import addresspng from '../images/address.png';
import addresspng1 from '../images/address1.png';

const Alltransactions = () => {
  const [twentyFourHoursAgotxn, settwentyFourHoursAgotxn] = useState({
    twentyFourHoursAgotxn: 0,
    totalFees24Hours: 0,
  });

  const [activeTab, setActiveTab] = useState("Validated");
  const [transactions, setTransactions] = useState([]);
  const navigate = useNavigate();
  const [pagenumber, setPagenumber] = useState(1);
  const [pagelength] = useState(20); // Set a default page length
  const [showTable, setShowTable] = useState(false); // State to control table visibility

  useEffect(() => {
    const fetchLastTxn = async () => {
      try {
        const response = await fetch('https://blockchain.fufi.info/getHomepagedata');
        const data = await response.json();
        settwentyFourHoursAgotxn(data.result);
      } catch (error) {
        console.error('Error fetching last 24 hours transactions:', error);
      }
    };
    fetchLastTxn();
  }, []);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await fetch(`https://blockchain.fufi.info/getAllTransction/${pagenumber}/${pagelength}`);
        const data = await response.json();

        setTransactions(data.result || []);
        setShowTable(true); // Show table after data is fetched
      } catch (error) {
        console.error('Error fetching all transactions:', error);
      }
    };
    fetchTransactions();
  }, [pagenumber, pagelength]);

  const shortenAddress = (length, address) => {
    return `${address.slice(0, length)}..${address.slice(-5)}`;
  };

  const handleAlladdressClick = (address) => {
    navigate(`/address/${address}`);
  };

  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content);
  };

  const formatTimeAgo = (date) => {
    const dates = new Date(date).getTime();
    const dateInSeconds = Math.floor(dates / 1000);
    let currentTime = Math.floor(Date.now() / 1000);
    let second = currentTime - dateInSeconds;
    if (second < 60) {
      return `${second}s ago`;
    } else if (second < 3600) {
      let minutes = Math.floor(second / 60);
      return `${minutes}m ago`;
    } else if (second < 86400) {
      let hours = Math.floor(second / 3600);
      return `${hours}h ago`;
    } else {
      let days = Math.floor(second / 86400);
      return `${days}d ago`;
    }
  };

  const trimValue = (value) => {
    let trimmedValue = Number(value).toFixed(5);
    trimmedValue = parseFloat(trimmedValue).toString();
    return trimmedValue;
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0) {
      setPagenumber(newPage);
    }
  };
  const handleTransactionClick = (hash) => {
    navigate(`/transaction/${hash}`);
  };
  return (
    <div className="Alltransaction-detail-container">
      {/* <Search /> */}
      <div className="Alltransaction-heading">Fufi Transactions</div>
      <div className="Alltransaction-grid">
        <div className="Alltransaction-grid-item">
          <div className="Alltransaction-grid-title">Transactions</div>
          <div className="Alltransaction-grid-value-container">
            <div className="Alltransaction-grid-value">{twentyFourHoursAgotxn.twentyFourHoursAgotxn}</div>
            <div className="Alltransaction-grid-subtitle">(24h)</div>
          </div>
        </div>
        <div className="Alltransaction-grid-item">
          <div className="Alltransaction-grid-title">Pending Transactions</div>
          <div className="Alltransaction-grid-value-container">
            <div className="Alltransaction-grid-value">0</div>
            <div className="Alltransaction-grid-subtitle">(1h)</div>
          </div>
        </div>
        <div className="Alltransaction-grid-item">
          <div className="Alltransaction-grid-title">Transaction Fees</div>
          <div className="Alltransaction-grid-value-container">
            <div className="Alltransaction-grid-value">{twentyFourHoursAgotxn.totalFees24Hours}</div>
            <div className="Alltransaction-grid-subtitle">(24h)</div>
          </div>
        </div>
        <div className="Alltransaction-grid-item">
          <div className="Alltransaction-grid-title">Avg. Transaction Fee</div>
          <div className="Alltransaction-grid-value-container">
            <div className="Alltransaction-grid-value">0.0002</div>
            <div className="Alltransaction-grid-subtitle">(24h)</div>
          </div>
        </div>
      </div>

      <div className="paginations">
        <span className="pagination-page-first" onClick={() => setPagenumber(1)}>
          First
        </span>
        <span className="pagination-button" onClick={() => handlePageChange(pagenumber - 1)}>
          <i className="fas fa-chevron-left"></i>
        </span>
        <span className="pagination-page-number">
          {pagenumber}
        </span>
        <span className="pagination-button-next" onClick={() => handlePageChange(pagenumber + 1)}>
          <i className="fas fa-chevron-right"></i>
        </span>
      </div>
      <div className="alltransaction-body">
        <div className="alltransaction-grid">
          {transactions.map((transaction, index) => (
            <div className="alltransaction-item" key={index}>
              <div className="transaction-info">
                <div className="transaction-status">
                  {transaction?.transctiontype === 1
                    ? 'Transfer'
                    : transaction?.transctiontype === 2
                      ? 'Contract call'
                      : 'Coin transfer'}
                </div>
              </div>
              <div className="transaction-hash"
                onClick={() => handleTransactionClick(transaction.hash)}
                style={{ cursor: "pointer" }}>
                <img
                  src={transactionlogo}
                  alt="block logo"
                  className="transactionlogo"
                />
                {shortenAddress(15, transaction.hash)}
              </div>
              
              <span className='transacHash'
                onClick={() => handleAlladdressClick(transaction.from)}
                style={{ cursor: "pointer" }}
              >
                {shortenAddress(6, transaction.from)}
              </span>
              <span
                className="alltransaction-to"
                onClick={() => handleAlladdressClick(transaction.to)}
                style={{ cursor: "pointer" }}
              >
                <i className="bi bi-arrow-right-square-fill"></i>
                {shortenAddress(
                  6,
                  transaction?.to === '0' ? transaction.contract : transaction.to
                )}
              </span>
              <span className="transaction-value">
                {trimValue(transaction.value)} Value
              </span>
              <span className="transaction-fee">
                {trimValue(transaction.fee)} Fee
              </span>

              <span className="transactionblock-number">
                {transaction.blockNumber} Block
              </span>
              <span className="transaction-time1">{formatTimeAgo(transaction.createdAt)}</span>
            </div>
          ))}
        </div>
      </div>

    </div>
  );
};

export default Alltransactions;
