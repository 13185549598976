import React, { useEffect, useState } from 'react';
import { Navbar, Nav, NavDropdown, Container, Alert } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Styles/Header.css';
import logo from '../images/fufi.png';
import flogo from '../images/flogo.png';
import Search from "./Searchbar";

import blockchainheaderlogo from "../images/icons/ghkk.png";
import transactionlogo from "../images/transaction.png";
import Block from "../images/Block.png";
import topaccountlogo from "../images/topaccountlogo.png";
import verifiedcontract from "../images/verifiedcontract.png";
import tokenslogo from "../images/icons/TokIcon.png";
import piechartlogo from "../images/icons/adsa.png";
import piechartAPI from "../images/icons/rtutru.png";

import apilogo from "../images/apilogo.png";
//import CircleSvg from "../images/block-time.svg";

import accountlogo from "../images/icons/artyuy.png";
import shutdown from "../images/shutdown.png";
import Deploycontract from "../images/Deploycontract.png";

const Header = () => {
  const [fufiprice, setFufiPrice] = useState({ usdt_price: '...' });
  const [showLogoutMsg, setShowLogoutMsg] = useState(false);
  const [hasToken, setHasToken] = useState(false);

  useEffect(() => {
    const fetchPrice = async () => {
      try {
        const response = await fetch('https://sid.fufi.info/getfufiprice');
        const data = await response.json();
        setFufiPrice(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchPrice();

    const token = localStorage.getItem('token');
    setHasToken(!!token); // Update hasToken state based on token presence
  }, []);

  const handleShutdown = () => {
    localStorage.removeItem('token');
    setShowLogoutMsg(true);
    setHasToken(false); // Update hasToken state
    setTimeout(() => {
      setShowLogoutMsg(false);
    }, 3000);
  };
  const trimValue = (value) => {
    let trimmedValue = Number(value).toFixed(5);
    trimmedValue = parseFloat(trimmedValue).toString();
    return trimmedValue;
  };
  const location = useLocation();
  const hideSearch = ['/Account,', '/Deploycontract'].includes(location.pathname);

  return (
    <div className='containerMainIn-first'>
      {showLogoutMsg && (
        <Alert variant="success" onClose={() => setShowLogoutMsg(false)} dismissible>
          Successfully logged out!
        </Alert>
      )}
      <div className='notification'>
        <span className='notificationprice'>Fufi ₹{trimValue(fufiprice.price)}</span>
      </div>
      <Navbar bg="light" expand="lg">
        <Container className="navbar-container">
          <div className="navbar-header">
            <Navbar.Brand as={Link} to="/" className="logo">
              <img
                src={logo}
                width="110"
                height="25"
                className="headertransactionlogo4"
                alt="Logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
          </div>
          <Navbar.Collapse id="basic-navbar-nav" className="navbar-collapse">
            <Nav className="mr-auto">
              <NavDropdown
                title={
                  <span className='headertransactionlogoDrp' style={{ padding: '1px 3px', height: 'auto' }}>
                    <img
                      src={blockchainheaderlogo}
                      alt="headertransactionlogo"
                      className="headertransactionlogo23"
                    /> Blockchain
                  </span>
                }
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item as={Link} to="/Alltransactions">
                  <img src={transactionlogo} alt="headertransactionlogo" className="headertransactionlogo" /> Transactions
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Allblock">
                  <img src={Block} alt="headertransactionlogo" className="headertransactionlogo" /> Blocks
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Topaccounts">
                  <img src={topaccountlogo} alt="headertransactionlogo" className="headertransactionlogo" /> Top accounts
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Deploycontract">
                  <img src={Deploycontract} alt="headertransactionlogo" className="headertransactionlogo" /> Deploy contract
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Verifiedfufi">
                  <img src={verifiedcontract} alt="headertransactionlogo" className="headertransactionlogo" /> Verified contracts
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link as={Link} to="/">
                <span className='headertransactionlogo' style={{ padding: '1px 3px', height: 'auto' }}>
                  <img src={tokenslogo} alt="headertransactionlogo" /></span>
                <span>Tokens</span>
              </Nav.Link>
              <Nav.Link as={Link} to="/">
                <span className='headertransactionlogo'><img src={piechartlogo} alt="headertransactionlogo" /></span>
                <span>Charts & stats</span>
              </Nav.Link>
              <Nav.Link as={Link} to="/">
                <span className='headertransactionlogo'><img src={piechartAPI} alt="headertransactionlogo" /></span>
                <span>API</span>
              </Nav.Link>
              <Nav.Link as={Link} to="/Dashboard">
                <span className='headertransactionlogo'><img src={accountlogo} alt="headertransactionlogo" /></span>
                <span>My Account</span>
              </Nav.Link>
              {hasToken && (
                <Nav.Link as={Link} to="/" onClick={handleShutdown}>
                  <span className='headertransactionlogo'>
                    <img src={shutdown} alt="headertransactionlogo" className="headertransactionlogo" />
                  </span>
                </Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {<Search />}

    </div>
  );
};

export default Header;
