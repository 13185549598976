import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Search from "./Searchbar";
import "../Styles/Allblock.css";
import addresspng from "../images/address.png";

const Allblock = () => {
  const [activeTab, setActiveTab] = useState("All");
  const [transactions, setTransactions] = useState([]);
  const [pagenumber, setPagenumber] = useState(1);
  const [pagelength] = useState(20); // Set a default page length
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await fetch(
          `https://blockchain.fufi.info/getBlocks/${pagenumber}/${pagelength}`
        );
        const data = await response.json();
        setTransactions(data.result || []);
      } catch (error) {
        console.error("Error fetching all transactions:", error);
      }
    };
    fetchTransactions();
  }, [pagenumber, pagelength]);

  const formatTimeAgoblock = (timestamp) => {
    let currentTime = Math.floor(Date.now() / 1000);
    let second = currentTime - timestamp;

    if (second < 60) {
      return `${second}s ago`;
    } else if (second < 3600) {
      let minutes = Math.floor(second / 60);
      return `${minutes}m ago`;
    } else if (second < 86400) {
      let hours = Math.floor(second / 3600);
      return `${hours}h ago`;
    } else {
      let days = Math.floor(second / 86400);
      return `${days}d ago`;
    }
  };

  const shortenAddress = (length, address) => {
    return `${address.slice(0, length)}...${address.slice(-4)}`;
  };

  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content);
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0) {
      setPagenumber(newPage);
    }
  };

  return (
    <div className="Allblock-detail-container">
      {/* <Search /> */}
      <div className="Allblock-heading">Blocks</div>

      <div className="Alltransaction-tabs">
        {["All", "Forked", "Uncles"].map((tab) => (
          <button
            key={tab}
            className={`tab-button ${activeTab === tab ? "active" : ""}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>

      <div className="pagination3">
        <span className="pagination-page-first" onClick={() => setPagenumber(1)}>
          First
        </span>
        <span className="pagination-button" onClick={() => handlePageChange(pagenumber - 1)}>
          <i className="fas fa-chevron-left"></i>
        </span>
        <span className="pagination-page-number">
          {pagenumber}
        </span>
        <span className="pagination-button-next" onClick={() => handlePageChange(pagenumber + 1)}>
          <i className="fas fa-chevron-right"></i>
        </span>
      </div>
      <div>
        {activeTab === "All" && (
          <div>
         
            <div className="Allblock-cards-container  Allblock-header7">
              <div className="Allblock-card">
              <div className="Allblocknumber-column">Block</div>
              <div className="Allblocknumber-column">Size, bytes</div>
              <div className="Allblocknumber-column">EBP</div>
              <div className="Allblocknumber-column">Txn</div>
              <div className="Allblocknumber-column">Gas used</div>
            </div>
            </div>
         

            <div className="Allblock-cards-container">
              {transactions.map((transaction) => (
                <div key={transaction.number} className="Allblock-card">
                  <div
                    className="Allblocknumber-column"
                    style={{ color: "#990000", fontWeight: 600 }}
                  >
                    <span style={{ cursor: "pointer" }} 
                    onClick={() => navigate(`/Block/${transaction.number}`)}>{transaction.number}</span>
                  </div>
                  <span className="Allblock-text-size" style={{fontWeight: 600 }}> size</span>
                  <div className="Allblocksize-column" style={{fontWeight: 600 }}>{transaction.size} </div>
                  <span className="Allblock-text-miner">EBP</span>
                  <div className="Allblock-miner">
                    <img
                      src={addresspng}
                      alt="addresspng logo"
                      className="Allblockaddresslogo"
                    />
                    <span style={{ color: "#333", fontWeight:"600" }}>
                      {shortenAddress(10, transaction.miner)}
                      <span
                        onClick={() => copyToClipboard(transaction.miner)}
                        className="Allblock-copy-icon"
                        style={{ color: "#white" }}
                        title="Copy To"
                      >
                        &#x2398;
                      </span>
                    </span>
                  </div>
                  <span className="Allblock-text-totaltransaction"> Txn</span>
                  <div className="Allblock-totaltransaction">
                    {transaction.totaltransaction}
                  </div>
                  <span className="Allblock-text-gasUsed"> gasUsed</span>
                  <div className="Allblock-gasUsed">{transaction.gasUsed}</div>
                  <span className="Allblock-time">
                    {formatTimeAgoblock(transaction.timestamp)}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}
        {activeTab === "Forked" && (
          <div className="Token-transfers">No Forked transactions</div>
        )}
        {activeTab === "Uncles" && (
          <div className="Token-transfers">No Uncles transactions</div>
        )}
      </div>
    </div>
  );
};

export default Allblock;
