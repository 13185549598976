import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import '../Styles/Signup.css'; // Import your CSS file
import logo from '../images/balancelogo.png';

const IssueForm = () => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    const [message, setMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const handleClose = () => setShowModal(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setMessage("Request successfully submitted");
    };
   

    return (
        <div>
        {message && <div className="alert alert-info mt-3">{message}</div>}

        <div className='login-details'>
            <div className='login-card'>
                <div className='Welcome'><strong>Feel free to reach us.</strong></div>
                <span>Submit an issue.</span>
                <div className='loginform'>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control1"
                                id="name"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="email"
                                className="form-control1"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="Enter email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <textarea
                                className="form-control1 description-box"
                                id="description"
                                placeholder="Issue description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </div>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </form>
                </div>
            </div>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>{message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
        </div>
    );
};

export default IssueForm;
