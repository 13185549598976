import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "../Styles/TransactionDetail.css"; // Import your CSS file for styling
import success from "../images/success.svg";
import flash from "../images/flash.png"; // Make sure to update the path to your logo
import addresspng1 from "../images/flogo.png";
import info from "../images/info.png";
import balancelogo from "../images/flogo.png";

const TransactionDetail = () => {
  const { hash } = useParams();
  const navigate = useNavigate();
  const [transactionData, setTransactionData] = useState(null);
  const [activeTab, setActiveTab] = useState("Details");
  const [traceData, setTraceData] = useState(null);

  useEffect(() => {
    const fetchTransactionData = async () => {
      try {
        const response = await fetch(
          `https://blockchain.fufi.info/getTransactionData/${hash}`
        );
        const data = await response.json();
        setTransactionData(data.result);
        setTraceData([
          {
            action: {
              callType: "call",
              from: data.result.from,
              gas: data.result.gas.toFixed(18),
              input: "R",
              to: data.result.to,
              value: data.result.value,
            },
            result: {
              gasUsed: data.result.fee,
              output: "0x",
            },
            subtraces: 0,
            traceAddress: [],
            type: "call",
          },
        ]);
      } catch (error) {
        console.error("Error fetching transaction data:", error);
      }
    };

    fetchTransactionData();
  }, [hash]);

  const shortenAddress = (length, address) => {
    return `${address.slice(0, length)}...${address.slice(-4)}`;
  };

  const trimValue = (value) => {
    let trimmedValue = Number(value).toFixed(2);
    trimmedValue = parseFloat(trimmedValue).toString();
    return trimmedValue;
  };

  const handleBlockClick = (blockNumber) => {
    navigate(`/Block/${blockNumber}`);
  };

  if (!transactionData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="transaction-detail-container">
      <div className="tnxheading">Transaction Detail</div>
      <div className="txnheading2">
        <img src={flash} alt="flash logo" className="flash-logo" />
        <div className="txn-info">
          <span style={{ color: "#718096", marginRight: "10px" }}>
            {transactionData?.transctiontype === 1
              ? " Transfer"
              : transactionData?.transctiontype === 2
              ? "Contract Call"
              : "Coin transfer"}
          </span>
          <span>{trimValue(transactionData.value)} Fufi</span>
          <span style={{ color: "#718096", marginLeft: "10px" }}> to </span>
          <img src={addresspng1} alt="addresspng logo" className="hashlogo" />
          <div className="txnaddress">{transactionData.to}</div>
          <div className="txnaddress1">
            {shortenAddress(10, transactionData.to)}
          </div>
        </div>
      </div>
      <div className="transaction-tabs">
        {["Details", "Token transfers", "Internal txns", "Raw trace"].map(
          (tab) => (
            <button
              key={tab}
              className={`transaction-button ${
                activeTab === tab ? "active" : ""
              }`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          )
        )}
      </div>

      <div className="transaction-detail">
        {activeTab === "Details" && (
          <>
            <span className="key">
              <img src={info} alt="miner logo" className="infologo2" />
              Transaction hash
            </span>
            <span className="text-hash">{transactionData.hash}</span>
            <span className="text-hash1">
              {shortenAddress(28, transactionData.hash)}
            </span>
            <span className="key">
              <img src={info} alt="miner logo" className="infologo2" />
              Status and method
            </span>
            <span className="text-value">
              {" "}
              <img src={success} alt="miner logo" className="infologo2" />
              {"Success"}
            </span>
            <span className="key">
              <img src={info} alt="miner logo" className="infologo2" />
              Block
            </span>
            <span
              className="text-value"
              style={{ color: "#1381bd", cursor: "pointer" }}
              onClick={() => handleBlockClick(transactionData.blockNumber)}
            >
              {" "}
              {transactionData.blockNumber}
            </span>
            <span className="key">
              <img src={info} alt="miner logo" className="infologo2" />
              Timestamp
            </span>
            <span className="text-value">{transactionData.createdAt}</span>
            <hr style={{ margin: "1px 0", color: "lightblue" }} />{" "}
            <span className="key">
              <img src={info} alt="miner logo" className="infologo2" />
              From
            </span>
            <span
              className="text-value"
              style={{ color: "#1381bd", cursor: "pointer" }}
              onClick={() => navigate(`/address/${transactionData.from}`)}
            >
              <img src={addresspng1} alt="miner logo" className="infologo2" />
              <span className="transaction-to">{transactionData.from}</span>
            </span>
            <span className="transaction-to1">
              {" "}
              {shortenAddress(20, transactionData.from)}
            </span>
            <span className="key">
              <img src={info} alt="miner logo" className="infologo2" />
              TO
            </span>
            <span
              className="text-value"
              style={{ color: "#1381bd", cursor: "pointer" }}
              onClick={() => navigate(`/address/${transactionData.to}`)}
            >
              <img src={addresspng1} alt="miner logo" className="infologo2" />
              <span className="transaction-to">{transactionData.to}</span>
            </span>
            <span className="transaction-to1">
              {" "}
              {shortenAddress(20, transactionData.to)}
            </span>
            <hr style={{ margin: "1px 0", color: "lightblue" }} />{" "}
            <span className="key">
              <img src={info} alt="miner logo" className="infologo2" />
              Value
            </span>
            <span className="text-value">
              {" "}
              <img src={balancelogo} alt="miner logo" className="infologo2" />
              {transactionData.value} Fufi
            </span>
            <span className="key">
              <img src={info} alt="miner logo" className="infologo2" />
              Transaction fee
            </span>
            <span className="text-value">{transactionData.fee} Fufi</span>
            <div className="detail-row"></div>
            {/* Horizontal line */}
            <span className="key">
              <img src={info} alt="miner logo" className="infologo2" />
              Gas
            </span>
            <span className="text-value">
              {transactionData.gas.toFixed(18)} Fufi
            </span>
            <span className="key">
              <img src={info} alt="miner logo" className="infologo2" />
              Gas Price
            </span>
            <span className="text-value">
              {transactionData.gasPrice.toFixed(18)} Fufi
            </span>
            <span className="key">
              <img src={info} alt="miner logo" className="infologo2" />
              Gas usage
            </span>
            <span className="text-value">
              {transactionData.gasUsed.toFixed(18)} Fufi
            </span>
            <span className="key">
              <img src={info} alt="miner logo" className="infologo2" />
              Nonce
            </span>
            <span className="text-value">{transactionData.nonce}</span>
            <span className="key">
              <img src={info} alt="miner logo" className="infologo2" />
              Transaction Index
            </span>
            <span className="text-value">
              {transactionData.transactionIndex}
            </span>
          </>
        )}

        {/* Add similar conditional rendering blocks for each tab's content */}
        {activeTab === "Token transfers" && (
          <div className="Token-transfers">There are no token transfers. </div>
        )}
        {activeTab === "User operations" && (
          <div className="User-operations">There are no user operations.</div>
        )}
        {activeTab === "Internal txns" && (
          <div className="Internal-txns">
            There are no internal transactions for this transaction.
          </div>
        )}

        {activeTab === "State" && <div>{/* State content */}</div>}
        {activeTab === "Raw trace" && traceData && (
          <div className="trace-content">
            {JSON.stringify(traceData, null, 2)}
          </div>
        )}
      </div>
    </div>
  );
};

export default TransactionDetail;
