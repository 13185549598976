// src/HomePage.js
import React, { useState, useEffect } from 'react';
import '../Styles/GridPage.css'; // Import your CSS file for styling
import Block from '../images/icons/ghkk.png'; // Make sure to update the path to your logo
import backintime from '../images/icons/sdgs.png'; // Make sure to update the path to your logo
import transactionlogo from '../images/icons/oiuwe.png'; // Make sure to update the path to your logo
import walletlogo from '../images/icons/xcvbbnbvj.png'; // Make sure to update the path to your logo
import gasoline from '../images/icons/ipipoi.png'; // Make sure to update the path to your logo

const GridPage = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://blockchain.fufi.info/getHomepagedata');
        const result = await response.json();
        setData(result.result);
      } catch (error) {
        console.error('Error fetching homepage data:', error);
      }
    };

    fetchData();
  }, []);


  return (
    <div className='containerMainIn-second'>

      <div className="grid-container">
        <div className="grid-item">
          <div className="grid-title">
            <div className="grid-titleInx">
              <img src={Block} alt="Block logo" />
            </div>
            <div className="grid-titleInx">
              <span className='grid-title-text'>Total blocks</span>
              <span>{data.BlockNumber}</span>
            </div>

          </div>
        </div>


        <div className="grid-item">
          <div className="grid-title">
            <div className="grid-titleInx">
              <img src={backintime} alt="backintime logo" className='gridstyles' />
            </div>
            <div className="grid-titleInx">
              <span className='grid-title-text'>Avg. block time</span>
              <span>{data.blocktime}.0s</span>
            </div>
          </div>
        </div>


        <div className="grid-item">
          <div className="grid-title">
            <div className="grid-titleInx">
              <img src={transactionlogo} alt="transactionlogo logo" />
            </div>
         
          <div className="grid-titleInx">
          <span className='grid-title-text'>Total Txns</span>
            <span>{data.length}</span>
            
          </div>
        </div>
        </div>

        <div className="grid-item">
          <div className="grid-title">
            <div className="grid-titleInx">
              <img src={walletlogo} alt="walletlogo logo" />
            </div>
            <div className="grid-titleInx">
            <span className='grid-title-text'>Wallet Address</span>
              <span>{data.totaladdress}</span>
             
            </div>
          </div>
        </div>


        <div className="grid-item">
          <div className="grid-title">
            <div className="grid-titleInx">
              <img src={gasoline} alt="gasoline logo" />
            </div>
            <div className="grid-titleInx">
            <span className='grid-title-text'>Gas tracker</span>
              <span>0.00021 Fufi</span>
            </div>
          </div>
        </div>


        </div>
      </div>
      );
};

      export default GridPage;
