import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "../Styles/Block.css"; // Import your CSS file for styling
import { Table, Row, Col } from "react-bootstrap";

import success from '../images/success.svg';
import transactionlogo from '../images/transaction.png';
import Block from '../images/Block.png';
import arrow from '../images/arrow.png';
import addresspng from '../images/address.png';
import addresspng1 from '../images/address1.png';
import info from '../images/info.png';

import Search from './Searchbar';

const Blockpage = () => {
  const { blockNumber } = useParams();
  const [blocks, setBlocks] = useState(null); // State to store block data

  const navigate = useNavigate();
  const [copyMessage, setCopyMessage] = useState("");
  const [activeTab, setActiveTab] = useState("Details");
  const [traceData, setTraceData] = useState(null);

  useEffect(() => {
    const fetchBlocks = async () => {
      try {
        const response = await fetch(
          `https://blockchain.fufi.info/getBlockNumberdata/${blockNumber}`
        );
        const data = await response.json();
        setBlocks(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchBlocks();
  }, [blockNumber]);

  // Render loading state if data is not yet available
  if (!blocks) {
    return <div>Loading...</div>;
  }

  const formatTimeAgoblock = (timestamp) => {
    let currentTime = Math.floor(Date.now() / 1000);
    let second = currentTime - timestamp;

    if (second < 60) {
      return `${second}s ago`;
    } else if (second < 3600) {
      let minutes = Math.floor(second / 60);
      return `${minutes}m ago`;
    } else if (second < 86400) {
      let hours = Math.floor(second / 3600);
      return `${hours}h ago`;
    } else {
      let days = Math.floor(second / 86400);
      return `${days}d ago`;
    }
  };


  const convertSecondsToIST = (timestamp) => {
    // Convert timestamp to milliseconds
    let milliseconds = timestamp * 1000;
    // Create a new Date object with the timestamp
    let date = new Date(milliseconds);
    // Get the IST time string
    let ISTTimeString = date.toLocaleString("en-IN", { timeZone: "Asia/Kolkata" });
    return ISTTimeString;
  };
  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content);
  };
  const shortenAddress = (length, address) => {
    return `${address.slice(0, length)}...${address.slice(-4)}`;
  };

  const shortenHash = (address) => {
    return `${address.slice(0, 12)}..${address.slice(-4)}`;
  };
  const handleTransactionClick = (hash) => {
    navigate(`/transaction/${hash}`);
  };

  const formatTimeAgo = (date) => {
    const dates = new Date(date).getTime();
    const dateInSeconds = Math.floor(dates / 1000);
    let currentTime = Math.floor(Date.now() / 1000);
    let second = currentTime - dateInSeconds;
    if (second < 60) {
      return `${second}s ago`;
    } else if (second < 3600) {
      let minutes = Math.floor(second / 60);
      return `${minutes}m ago`;
    } else if (second < 86400) {
      let hours = Math.floor(second / 3600);
      return `${hours}h ago`;
    } else {
      let days = Math.floor(second / 86400);
      return `${days}d ago`;
    }
  };


  const handleaddressClick = (address) => {
    navigate(`/address/${address}`);
  };
  const trimValue = (value) => {
    let trimmedValue = Number(value).toFixed(5);
    trimmedValue = parseFloat(trimmedValue).toString();
    return trimmedValue;
  };
  return (
    <div className="transaction-detail-container">
    <div className="transaction-detail6">
      <div className="heading4">Block #{blocks.blockdata?.number} </div>
      <div className="Validatedby">
      <div className="Validatedby2">
        <span>EBP Validator</span>
        <span>
      <img
        src={addresspng1}
        alt="addresspng logo"
        className="blockminerlogo6"
      />
        </span>  </div>
        <div className="Validatedby2">
        <span className="ValidatedbyCont">
      
          <b className="bitaXcmd">
          {blocks.blockdata?.miner}
          </b>
        
          <b className="bitaXcmd">
        {shortenAddress(15, blocks.blockdata?.miner)}
        </b>
        
        </span>
        </div>
        </div>

      


      <div className="block-tabs6">
        {["Details", "Transactions", "Blob txns"].map((tab) => (
          <button
            key6={tab}
            className={`tab-button ${activeTab === tab ? "active" : ""}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>


      

        {activeTab === "Details" && (
          <>
           <div className="transaction-detail12">
            <span className="key6"> <img
              src={info}
              alt="miner logo"
              className="infologo6"
            />Block height</span>
            <span className="text-value6">{blocks.blockdata?.number}</span>
            </div>
            




            <div className="transaction-detail12">
            <span className="key6"> <img
              src={info}
              alt="miner logo"
              className="infologo6"
            />Size</span>
            <span className="text-value6">{blocks.blockdata?.size}</span>
            </div>
            




            <div className="transaction-detail12">
            <span className="key6"> <img
              src={info}
              alt="miner logo"
              className="infologo6"
            />Timestamp</span>
            <span className="text-value6">{formatTimeAgoblock(blocks.blockdata?.timestamp)}</span>
            </div>
            {/* <span className="timestamp-ist">| {convertSecondsToIST(blocks.blockdata?.timestamp)}</span> */}

            



            <div className="transaction-detail12">
            <span className="key6">  <img
              src={info}
              alt="miner logo"
              className="infologo6"
            />Transactions</span>
            <span className="text-value6" style={{ color: "#1381bd" }}>{blocks.transactionData.length} in this block</span>
            </div>
           
           
            <div className="transaction-detail12">
            <span className="key6"> <img
              src={info}
              alt="miner logo"
              className="infologo6"
            />EBP Validator</span>
            <span className="text-value6" style={{ color: "#1381bd" }}>
              <img
                src={addresspng1}
                alt="miner logo"
                className="infologo6"
              />
              {shortenAddress(20, blocks.blockdata?.miner)}
            </span>
            </div>




            <hr style={{ margin: "10px 0", color: "lightblue" }} />{" "}
            <div className="transaction-detail12">
            <span className="key6"> <img
              src={info}
              alt="miner logo"
              className="infologo6"
            />Gas used</span>
            <span className="text-value6">{blocks.blockdata?.gasUsed} ₹</span>
            </div>
           
           
           
            <div className="transaction-detail12">
            <span className="key6"> <img
              src={info}
              alt="miner logo"
              className="infologo6"
            />gasLimit</span>
            <span className="text-value6">{blocks.blockdata?.gasLimit}</span>
            </div>
           
           
            <div className="transaction-detail12">
            <span className="key6"> <img
              src={info}
              alt="miner logo"
              className="infologo6"
            />baseFeePerGas</span>
            <span className="text-value6">{blocks.blockdata?.baseFeePerGas}</span>
            </div>
            <hr style={{ margin: "10px 0", color: "lightblue" }} />{" "}
           
           
           
           
            <div className="transaction-detail12">
            <span className="key6"> <img
              src={info}
              alt="miner logo"
              className="infologo6"
            />Difficulty</span>
            <span className="text-value6">{blocks.blockdata?.difficulty}</span>
            </div>
         
         
            <div className="transaction-detail12">
            <span className="key6"> <img
              src={info}
              alt="miner logo"
              className="infologo6"
            />Total difficulty</span>
            <span className="text-value6">{blocks.blockdata?.totalDifficulty}</span>
            </div>
            <hr style={{ margin: "10px 0", color: "lightblue" }} />{" "}
           
           
           
            <div className="transaction-detail12">
            <span className="key6"> <img
              src={info}
              alt="miner logo"
              className="infologo6"
            />Hash</span>
            <span className="text-value6">{shortenAddress(28, blocks.blockdata?.hash)}</span>
            </div>
            
            
            <div className="transaction-detail12">
            <span className="key6" > <img
              src={info}
              alt="miner logo"
              className="infologo6"
            />Parent hash</span>
            <span className="text-value6 " style={{ color: "#1381bd" }}>{shortenAddress(28, blocks.blockdata?.parentHash)}</span>
            </div>
           
           
            <div className="transaction-detail12">
            <span className="key6"> <img
              src={info}
              alt="miner logo"
              className="infologo6"
            />Nonce</span>
            <span className="text-value6">{blocks.blockdata?.nonce}</span>
            </div>

          </>

        )}

        {activeTab === "Transactions" && (
          <div>
            <div className='Alltransaction-desktop20'>

              <Table>
                <tbody>
                  {blocks.transactionData.length > 0 ? (

                    blocks.transactionData.map((transaction, index) => (
                      <tr key6={index}>
                        <td>
                          <div className='Alltransaction-hash'>
                            {shortenAddress(12, transaction.hash)}
                          </div>

                        </td>
                        <td>
                          <div className='Alltransaction-type'>
                            {/* <div className='Alltransaction-status'>
                         coin transfer
                       </div> */}
                            <div className='Alltransaction-status1'>
                              success
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='block-number'>{transaction.blockNumber}</div>
                        </td>
                        <td>
                          <div className='Alltransaction-transfer'>transfer</div>
                        </td>
                        <td>
                          <div className="Alltransaction-twoaddress6">
                            <div className="Alltwoaddress">
                            <img src={addresspng} alt="Transaction logo" className="Alltransaction-address-logo6" />{" "}
                            <div className="Alltransaction-address">
                              <span onClick={() => handleaddressClick(transaction.from)} style={{ cursor: "pointer" }}>
                                {shortenAddress(5, transaction.from)}
                              </span>
                              <span onClick={() => copyToClipboard(transaction.from)} className="copy-icon" title="Copy From">&#x2398;</span>
                            </div>
                            </div>
                            <div className="Alltwoaddress">
                            <img src={addresspng1} alt="Transaction logo" className="Alltransaction-address-logo6" />{" "}
                            <div className="Alltransaction-address">
                              <span onClick={() => handleaddressClick(transaction.to)} style={{ cursor: "pointer" }}>
                                {shortenAddress(5, transaction.to)}
                              </span>
                              <span onClick={() => copyToClipboard(transaction.to)} className="copy-icon" title="Copy To">&#x2398;</span>
                            </div>
                            </div>
                         
                          </div>
                        </td>
                        <td>
                          <div className="Alltransaction-amount">
                            {trimValue(transaction.value)}<span className="Alltransaction-unit"> Value</span>
                          </div>
                          <div className="Alltransaction-fee">
                            {trimValue(transaction.fee)}<span className="Alltransaction-unit"> Fee</span>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <div className="Internal-txns">
                      No transactions found
                    </div>
                  )}
                </tbody>
              </Table>
            </div>
            <div className="Alltransaction-cards">
              <Table table>
                <tbody>
                  {blocks.transactionData.map((transaction, index) => (
                    <tr key6={transaction.address} style={{ cursor: "pointer" }}>
                      <td>
                        
                          <img src={transactionlogo} alt="block logo" className="Topaccounts-address-logo-mobile" />

                          <div className='Topaccounts-address-mobile'>{shortenAddress(15, transaction.hash)}</div>

                          <img src={addresspng} alt="block logo" className="Topaccounts-address-logo-mobile" />
                          <div className='Topaccounts-address-mobile'>{shortenAddress(5, transaction.from)}</div>
                          
                          <span className="innerTables">
                          
                          <img src={arrow} alt="Transaction logo" className="Topaccounts-arror-logo6" />{" "}
                          <img src={addresspng1} alt="block logo" className="Topaccounts-address1-logo-mobile6" />
                          <span className='Topaccounts-address1-mobile'>{shortenAddress(5, transaction.from)}</span>
                          <br />

                          <div className="Topaccounts-data-mobile8">Value {transaction.value}</div>
                        
                          <div className="Topaccounts-data-mobile8">Fee {transaction.fee}</div>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        )}

        {activeTab === "Blob txns" && (
          <div className="Internal-txns">
            No Blob txns
          </div>
        )}
      </div>
    </div>
  );
};

export default Blockpage;
